import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import { permissions } from "../constants/permissions";
import { Spinner } from "../components/UIKits/Spinner/Spinner";
import ConfirmAccount from "../pages/ConfirmAccount/ConfirmAccount";

const Login = lazy(() => import("../pages/Login/Login"));
const HomePage = lazy(() => import("../pages/Home/Home"));
const ForgotPasswordPage = lazy(
  () => import("../pages/ForgotPassword/ForgotPassword")
);
const OtpLoginPage = lazy(() => import("../pages/Login/OtpLogin"));
const ResetPasswordPage = lazy(
  () => import("../pages/ResetPassword/Resetpassword")
);
const PayoutsPage = lazy(() => import("../pages/Payouts/Payouts"));
const VendorsPage = lazy(() => import("../pages/Vendors/Vendors"));
const RequestsPage = lazy(() => import("../pages/Requests/Requests"));
const SettingsPage = lazy(() => import("../pages/Settings/Settings"));
const AuditLogPage = lazy(() => import("../pages/AuditLog/Auditlog"));
const NotificationsPage = lazy(
  () => import("../pages/Notifications/Notifications")
);
const VendorTabDetailsPage = lazy(
  () => import("../pages/Vendors/VendorTabDetails")
);
const WorkflowDetailPage = lazy(
  () => import("../pages/Settings/WorkflowDetail/WorkflowDetail")
);
const RequestDetailsPage = lazy(
  () => import("../pages/RequestDetails/RequestDetails")
);

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="h-screen flex items-center justify-center">
          {" "}
          <Spinner />.
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/otp-login" element={<OtpLoginPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/account/confirm" element={<ConfirmAccount />} />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute requiredPermissions={[]}>
              {" "}
              <NotificationsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/home"
          element={
            <ProtectedRoute requiredPermissions={[]}>
              {" "}
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payouts"
          element={
            <ProtectedRoute requiredPermissions={[permissions.PAYOUT]}>
              <PayoutsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendors"
          element={
            <ProtectedRoute
              requiredPermissions={[permissions.VENDOR_INFORMATION]}
            >
              <VendorsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/requests"
          element={
            <ProtectedRoute requiredPermissions={[permissions.REQUEST]}>
              <RequestsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/requests/details/:id"
          element={
            <ProtectedRoute requiredPermissions={[permissions.REQUEST]}>
              <RequestDetailsPage />
              {/* <WorkflowDetailPage /> */}
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute requiredPermissions={[permissions.SETTING_USER]}>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/workflow-detail/:id"
          element={
            <ProtectedRoute
              requiredPermissions={[permissions.SETTING_APPROVAL_WORKFLOW]}
            >
              <WorkflowDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/audit-log"
          element={
            <ProtectedRoute requiredPermissions={[permissions.AUDIT_LOG]}>
              <AuditLogPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/vendor-details"
          element={
            <ProtectedRoute
              requiredPermissions={[permissions.VENDOR_INFORMATION]}
            >
              <VendorTabDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
